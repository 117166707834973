<template>
  <data-view :columnsFormat="columnsFormat" :askData="op_name" @refreshDo="chartRefresh">
  </data-view>
</template>
<script>
import dataView from "@/components/Common/DataView.vue";

export default {
  name: "industryAccidentList",
  props: [],
  components: {
    dataView,
  },
  data() {
    return {
      op_name: this.$options.name,
      updateCount: 1,
      echartSize: { height: "700px", width: "1000px" },
      columnsFormat: [
        // {
        //   title: "学习时间",
        //   key: "study_date",
        //   width: 150,
        //   align: "center"
        // },
        {
          title: "案例名称",
          key: "name",
          width: 270,
          align: "center"
        },
        {
          title: "事件简述",
          key: "description",
          align: "center",
          tooltip: "true",
          minWidth: 150
          /*render:(h,p)=>{
                            let ob = {name:"accident_c",params:{accident_id:p.row.id}}
                            return h('router-link',{
                            attrs:{
                                to:ob                                
                            }},p.row.description)
                        }*/
        },
        // {
        //   title: "学习部门",
        //   key: "study_department",
        //   align: "center"
        // },
      ]
    };
  },
  methods: {
    chartRefresh() {
      this.updateCount++;
    }
  }
};
</script>
<style scoped>
.single-chart-wrapper{
  margin-top:10px;
}
</style>